import { FC, useMemo } from 'react';
import { HotelType } from 'types/types';
import OurHotelNumber from 'assets/svg/our-hotels-4000.svg';
import Button from 'components/Button/BaseButton';
import Link from 'next/link';
import HotelSlide from './HotelSlide';
import styles from './OurHotels.module.scss';
import Carousel from 'components/carousel/Carousel';

interface IOurHotels {
  ourHotels: HotelType[];
}

const OurHotels: FC<IOurHotels> = ({ ourHotels }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <div className={styles.titleInner}>
          <h3>Our hotels</h3>
          <div>
            <p>Find your next luxury stay in one of our</p>
            <div className={styles.titleSVG}>
              <OurHotelNumber viewBox="0 0 105 52" />
            </div>
            <p>hotels worldwide</p>
          </div>
          <Link href={`our-hotels`}>
            <Button>Explore Hotels</Button>
          </Link>
        </div>
      </div>
      <div className={styles.carousel}>
        <Carousel customStyles={styles} minimumScrollDistance={50}>
          {ourHotels.map(hotel => (
            <HotelSlide key={hotel.id} hotel={hotel} />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default OurHotels;
