import { FC } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { HotelType } from 'types/types';
import styles from './HotelSlide.module.scss';
import { Heading } from 'components/text';

interface HotelSlideProps {
  hotel: HotelType;
}

const HotelSlide: FC<HotelSlideProps> = ({ hotel }) => {
  return (
    <div className={styles.hotelSlide}>
      <Link href={`/our-hotels/${hotel.hotel_id}`}>
        <div className={styles.hotelSlideContent}>
          <div className={styles.hotelSlideImage}>
            <Image
              src={hotel.image}
              alt={hotel.name}
              layout="fill"
              objectFit="cover"
            />
          </div>
          <Heading as="h5">{hotel.name}</Heading>
        </div>
      </Link>
    </div>
  );
};

export default HotelSlide;
