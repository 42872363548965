import React, { useEffect, useState, useRef, FC, ReactNode } from 'react';
import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react';
import { ArrowLeft, ArrowRight } from 'components/icons';
import styles from './Carousel.module.scss';
import cx from 'classnames';

/**
 * These are the carousel elements that can be styled externally
 * via a customStyles prop
 */
interface CustomStyles {
  carouselContainer?: string;
  carouselButtons?: string;
  carouselViewport?: string;
  carouselSlidesContainer?: string;
  carouselPrevButton?: string;
  carouselNextButton?: string;
}

interface CarouselProps {
  // Options for configuring the Embla Carousel library. Overrides defaultOptions
  options?: EmblaOptionsType;
  // The slides to be displayed
  children: ReactNode;
  // Object containing custom styles for carousel elements
  customStyles?: CustomStyles;
  // minimum distance allowed for scroll. Used to prevent tiny end scrolls
  minimumScrollDistance?: number;
}

const defaultOptions: EmblaOptionsType = {
  align: 'start',
  loop: false,
  containScroll: 'trimSnaps',
  draggable: false,
  breakpoints: {
    '(max-width: 767px)': { draggable: true },
  },
};

const Carousel: FC<CarouselProps> = ({
  options = defaultOptions,
  children,
  customStyles,
  minimumScrollDistance,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [canScrollPrev, setCanScrollPrev] = useState<boolean>(false);
  const [canScrollNext, setCanScrollNext] = useState<boolean>(true);

  const onScrollPrev = () => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
    updateScrollButtons();
  };

  const onScrollNext = () => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
    updateScrollButtons();

    /**
     * This is to disable scrolling when the final scroll amount is very small
     */
    if (
      minimumScrollDistance != null &&
      minimumScrollDistance > 0 &&
      !options.loop
    ) {
      const engine = emblaApi.internalEngine();
      const currentIndex = emblaApi.selectedScrollSnap();
      const prevTarget = engine.target.get();
      // fake scroll to check distance to next slide
      emblaApi.scrollNext();
      const nextTarget = engine.target.get();
      const nextScrollDistance = Math.abs(nextTarget - prevTarget);
      if (nextScrollDistance < minimumScrollDistance) {
        setCanScrollNext(false);
      } else {
        setCanScrollNext(true);
      }
      // cancel scroll by scrolling to current slide
      emblaApi.scrollTo(currentIndex);
    }
  };

  const updateScrollButtons = () => {
    if (!emblaApi) return;
    setCanScrollPrev(emblaApi.canScrollPrev());
    setCanScrollNext(emblaApi.canScrollNext());
  };

  useEffect(() => {
    if (!emblaApi) return;
    const handleResize = () => {
      setCanScrollPrev(emblaApi.canScrollPrev());
      setCanScrollNext(emblaApi.canScrollNext());
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [emblaApi]);

  return (
    <div
      className={cx(styles.carouselContainer, customStyles?.carouselContainer)}
    >
      <div
        className={cx(styles.carouselButtons, customStyles?.carouselButtons)}
      >
        <button
          onClick={onScrollPrev}
          disabled={!canScrollPrev}
          className={customStyles?.carouselPrevButton}
        >
          <ArrowLeft />
        </button>
        <button
          onClick={onScrollNext}
          disabled={!canScrollNext}
          className={customStyles?.carouselNextButton}
        >
          <ArrowRight />
        </button>
      </div>
      <div
        className={cx(styles.carouselViewport, customStyles?.carouselViewport)}
        ref={emblaRef}
      >
        <div
          className={cx(
            styles.carouselSlidesContainer,
            customStyles?.carouselSlidesContainer
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
